// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novosti-js": () => import("./../../../src/pages/novosti.js" /* webpackChunkName: "component---src-pages-novosti-js" */),
  "component---src-pages-onama-js": () => import("./../../../src/pages/onama.js" /* webpackChunkName: "component---src-pages-onama-js" */),
  "component---src-pages-projekti-js": () => import("./../../../src/pages/projekti.js" /* webpackChunkName: "component---src-pages-projekti-js" */),
  "component---src-pages-usluge-js": () => import("./../../../src/pages/usluge.js" /* webpackChunkName: "component---src-pages-usluge-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-novosti-post-js": () => import("./../../../src/templates/novosti-post.js" /* webpackChunkName: "component---src-templates-novosti-post-js" */)
}

